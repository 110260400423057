import React from 'react';


function Features() {
  return (
    <>
      <main id="main">
        {/* ======= Features Section ======= */}
        <div className="col-md-5" data-aos="fade-up">
        
          <h1 className="section-heading fea">Features</h1>
          <img
            src="../assets/img/gym.png"
            alt="Underline"
            className="underline-img"
          />
        </div>
        <section className="section sec-1">
           <div className="container1">
          
            <div className="row  align-items-center no-wrap">
              <div className="col-md-6 one" data-aos="fade-left">
                <img
                  src="../assets/img/dashboard1.png"
                  alt="Image"
                  className="img-fluid img-1"
                />
              </div>
              <div className="col-md-6 two">
                <h4 >Dashboard</h4>
                <p>Power-full reporting and dashboard</p>
              </div>
            </div>
           </div> 
        </section>
        <section className="section sec-1">
        <div className="container1">
            <div className="row align-items-center no-wrap1">
              <div className="col-md-6 one">
                <h4 >Members</h4>
                <p>Manage your members with ease</p>
              </div>
              <div className="col-md-6 two" data-aos="fade-left">
                <img
                  src="../assets/img/members.png"
                  alt="Image"
                  className="img-fluid img-1"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section sec-1">
        <div className="container1">
            <div className="row align-items-center no-wrap">
              <div className="col-md-6 one" data-aos="fade-left">
                <img
                  src="../assets/img/quick-function.png"
                  alt="Image"
                  className="img-fluid img-1"
                />
              </div>
              <div className="col-md-6 two">
                <h4 >Quick Function</h4>
                <p>Quick call, sms and connect features</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section sec-1">
        <div className="container1">
            <div className="row align-items-center no-wrap1">
              <div className="col-md-6 one">
                <h4>Manage Multiple Centers</h4>
                <p>
                Manage your multiple Centers with single app
                </p>
              </div>
              <div className="col-md-6 two" data-aos="fade-left">
                <img
                  src="../assets/img/explore.png"
                  alt="Image"
                  className="img-fluid img-1"
                />
              </div>
            </div>
            </div>
          </section>
        <section className="section sec-1 back-g">
        <div className="container1">
            <div className="row align-items-center no-wrap">
              <div className="col-md-6 one" data-aos="fade-left">
                <img
                  src="../assets/img/package1.png"
                  alt="Image"
                  className="img-fluid img-1"
                />
              </div>
              <div className="col-md-6 two">
                <h4 className="mb-4">Package</h4>
                <p className="mb-4">
                Track pending payments, expiring packages
                </p>
              </div>
            </div>
            </div>
          </section>
          <section className="section sec-1">
          <div className="container1">
          <div className="row align-items-center no-wrap1">
            <div className="col-md-6 one">
              <h4>Expense Management</h4>
              <p>
              Manage and track center expenses
               </p>
            </div>
            <div className="col-md-6 two" data-aos="fade-left">
              <img
                src="../assets/img/bill.png"
                alt="Image"
                className="img-fluid img-1"
              />
            </div>
          </div>
          </div>
        </section>
        <section className="section sec-1 back-g">
        <div className="container1">
         <div className="row align-items-center no-wrap">
           <div className="col-md-6 one" data-aos="fade-left">
             <img
               src="../assets/img/jane.png"
               alt="Image"
               className="img-fluid img-1"
             />
           </div>
           <div className="col-md-6 two">
             <h4>Manage Enquiry</h4>
             <p>
             Enquiry management and easy follow ups. Forgot manual paperwork
             </p>
           </div>
         </div>
         </div>
       </section>
       <section className="section sec-1">
       <div className="container1">
          <div className="row align-items-center no-wrap1">
            <div className="col-md-6 one">
              <h4>Multi Language Support</h4>
              <p>
              Available in English & Hindi. More coming soon
               </p>
            </div>
            <div className="col-md-6 two" data-aos="fade-left">
              <img
                src="../assets/img/language.png"
                alt="Image"
                className="img-fluid img-1"
              />
            </div>
          </div>
          </div>
        </section>
        {/* End CTA Section */}
      </main>
      {/* End #main */}
    </>
  );
}

export default Features;

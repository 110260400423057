import React from "react";

function Video() {
  return (
    <section className="section sec-1">
    <div className="container">
      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        <div className="col-md-8 mb-5">
          <div className="iframe-container">
            <iframe
              src="https://www.youtube.com/embed/5UWF1IFT5Zo?si=qhtGDWWE0nh-m4VM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Video;


// import RoutesComponent from './routes/Routes';
import './App.css';
import Layout from './layout/Layout';

function App() {
  return (
    <>
    {/* <RoutesComponent/> */}
    <Layout/>
    </>
  );
}

export default App;

import React from 'react';

function Pricing() {
  return (
    <section className="section sec-1">
      <div className="container">
        <div className="col-md-12" data-aos="fade-up">
          <h2 className="section-heading fea">User App</h2>
        </div>
        <div className="row no-wrap2">
          <div className="col-md-3 colom col-sm-6" data-aos="fade-left">
            <img
              src="../assets/img/track.png"
              alt="Track"
              className="img-fluid2 img-1"
            />
          </div>
          <div className="col-md-3 colom col-sm-6" data-aos="fade-left">
            <img
              src="../assets/img/language.png"
              alt="Language"
              className="img-fluid2 img-1"
            />
          </div>
          {/* <div className="col-md-3 colom2 col-sm-6" data-aos="fade-left">
            <img
              src="../assets/img/Attendance.png"
              alt="Attendance"
              className="img-fluid3 img-1"
            />
          </div>
          <div className="col-md-3 colom2 col-sm-6" data-aos="fade-left">
            <img
              src="../assets/img/notification.png"
              alt="Notification"
              className="img-fluid3 img-1"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Pricing;

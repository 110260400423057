import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <section className="download-app text-center py-5 cent">
        <div className="container">
          <div className="row pr-0">
            <h1 className="mb-3">Download The Apps Now!</h1>
            <div className="d-flex btn-app align-items-center justify-content-center">
              <div>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ilomatech.fitnessmaaadmin"
                >
                  <img src="assets/img/playstore 1.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between foot">
                <div className="copy">
                  © {currentYear} FITNESSMAA POWERED BY iLOMA TECHNOLOGY PVT. LTD.
                </div>
                <div>
                  <ul className="d-flex align-items-center icon">
                    <li>
                      <a href="mailto:support@fitnessmaa.com">
                        <img src="assets/img/Email.png" alt="Email" />
                      </a>
                    </li>
                    {/* <li><a href=""><img src="assets/images/Linkdin.png" /></a></li> */}
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/fitnessmaaa/?hl=en"
                      >
                        <img src="assets/img/Insta.png" alt="Instagram" />
                      </a>
                    </li>
                    {/* <li><a href=""><img src="assets/images/Facebook.png" /></a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>





//     <section className="bg-primary text-white text-center text-lg-start">
//       <div className=" p-4">
//         <div className="row">
//           <div className="col-lg-6">
//             hello
//           </div>
//           <div className="col-lg-6">
//             hello2
//           </div>
//         </div>
//       </div>
//   {/* Grid container */}
//   <div className="container p-4">
//     {/*Grid row*/}
//     <div className="row">
//       {/*Grid column*/}
//       <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
//         <h5 className="text-uppercase">Footer Content</h5>
//         <p>
        // Fitness Maa is king. Nutrition is queen. Put them together and
        //      you’ve got a kingdom" provides you a best and new features where
        //      you can login and choose language splash with Newly featured
        //      Dashboard included all various fitness option and centers for Male,
        //      female, Unisex in your areas where you can see the payment
        //      history easily download your invoice, packaged purchase detail
        //     according to your fitness to avoid illness and keep healthy.    
//         </p>
//       </div>
//       {/*Grid column*/}
//       {/*Grid column*/}
//       {/*Grid column*/}
//       {/*Grid column*/}
//       <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
//       <h3>Downloads</h3>
//                 <ul className="list-unstyled">
//                    <li>
//                      <a href="https://play.google.com/store/apps/details?id=com.ilomatech.fitnessmaaadmin&pcampaignid=web_share">FitnessMaa - Gym Management</a>
//                   </li>
//                <li>
//                      <a href="https://play.google.com/store/apps/details?id=com.ilomatech.fitnessmaauser&pcampaignid=web_share">FitnessMaa - User App</a>
//                   </li>
//                </ul>
//       </div>
//       {/*Grid column*/}
//     </div>
//     {/*Grid row*/}
//   </div>
//   {/* Grid container */}
//   {/* Copyright */}
//   <div
//     className="text-center p-3"
//     style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
//   >
//     © 2020 Copyright:
//     <a className="text-white" href="https://mdbootstrap.com/">
//       MDBootstrap.com
//     </a>
//   </div>
//   {/* Copyright */}
// </section>

    // <footer className="footer" role="contentinfo">
    //   <div className="container">
    //     <div className="row row2">
    //       <div className="col-md-3 col-sm-6 mb-4 mb-md-0">
    //         <img src="path_to_logo" alt="SoftLand Logo" className="footer-logo" />
    //         <p>
    //           Fitness Maa is king. Nutrition is queen. Put them together and
    //           you’ve got a kingdom" provides you a best and new features where
    //           you can login and choose language splash with Newly featured
    //           Dashboard included all various fitness option and centers for Male,
    //           female, Unisex in your areas where you can see the payment
    //           history easily download your invoice, packaged purchase detail
    //           according to your fitness to avoid illness and keep healthy. Take
    //           care of your body it's the only place you have to live.
    //         </p>
    //         <p className="social">
    //           <a href="#">
    //             <span className="bi bi-twitter" />
    //           </a>
    //           <a href="#">
    //             <span className="bi bi-facebook" />
    //           </a>
    //           <a href="#">
    //             <span className="bi bi-instagram" />
    //           </a>
    //           <a href="#">
    //             <span className="bi bi-linkedin" />
    //           </a>
    //         </p>
    //       </div>
    //       <div className="col-md-9 col-sm-6 ms-auto">
    //         <div className="row site-section pt-0">
    //           <div className="col-md-12 mb-4 mb-md-0">
                // <h3>Downloads</h3>
                // <ul className="list-unstyled">
                //   <li>
                //     <a href="https://play.google.com/store/apps/details?id=com.ilomatech.fitnessmaaadmin&pcampaignid=web_share">FitnessMaa - Gym Management</a>
                //   </li>
                //   <li>
                //     <a href="https://play.google.com/store/apps/details?id=com.ilomatech.fitnessmaauser&pcampaignid=web_share">FitnessMaa - User App</a>
                //   </li>
                // </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row justify-content-center text-center">
    //       <div className="col-md-7">
    //         <p className="copyright">
    //           © Copyright Fitnessmaa. All Rights Reserved
    //         </p>
    //         <div className="credits">
    //           Designed by <a href="#">YourDesignCompany</a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
  );
}

export default Footer;

import React, { useState } from 'react';

function Navbar() {
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [activeLink, setActiveLink] = useState('#');

  const toggleMobileNav = () => {
    setMobileNavActive(!mobileNavActive);
  };

  const handleNavLinkClick = (href) => {
    setActiveLink(href);
    setMobileNavActive(false);
  };

  return (
    <>
      <header id="header" className={`fixed-top d-flex header-scrolled align-items-center${mobileNavActive ? ' header-scrolled' : ''}`}>
        <div className="container d-flex justify-content-between align-items-center">
          <nav id="navbar" className={`navbar${mobileNavActive ? ' navbar-mobile' : ''}`}>
            <div className="logo">
              {/* <h1>
                <href href="#">SoftLand</href>
              </h1> */}
              {/* Uncomment below if you prefer to use an image logo */}
              <a href="#"><img src="../assets/img/logo_fit.png" alt="" className="img-fluid" /></a> 
            </div>
            <ul>
              <li>
                <a href="#" className={activeLink === '#' ? 'active' : ''} onClick={() => handleNavLinkClick('#')}>Home</a>
              </li>
              <li>
                <a href="#main" className={activeLink === '#main' ? 'active' : ''} onClick={() => handleNavLinkClick('#main')}>Features</a>
              </li>
              <li>
                <a href="#contact" className={activeLink === '#contact' ? 'active' : ''} onClick={() => handleNavLinkClick('#contact')}>Contact Us</a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileNav}></i> 
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;

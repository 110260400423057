import React from "react";
import { BrowserRouter as Router, Outlet } from 'react-router-dom';
import Navbar from "../pages/Navbar";
import Header from "../pages/Header"
import Features from "../pages/Features";
import Home_section from "../pages/Home_section";
import Footer from "../pages/Footer";
import Review from "../pages/Review";
import Video from "../pages/Video";
import Contactus from "../pages/Contactus";
import Pricing from "../pages/OtherApp";

const Layout = () => {
  return (
    <>
     <Navbar/>
      <Outlet />  
      <Header/>
      {/* <Home_section/> */}
      <Features/>
      <Video/>
      {/* <Pricing/> */}
      <Review/>
      {/* <Contactus/> */}
      <Footer/>
      {/* <Features/> */}
      
      
    </>
  );
};

export default Layout;

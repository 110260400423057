import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import Swiper styles
import "swiper/css/pagination"; // Import pagination styles
import { Pagination, Autoplay } from "swiper/modules"; // Import Pagination and Autoplay modules

function Review() {
  return (
    <>
      {/* ======= Testimonials Section ======= */}
      <section className="section border-top border-bottom">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-4">
              <h2 className="section-heading">Review From Our Users</h2>
              <img
            src="../assets/img/gym.png"
            alt="Underline"
            className="underline-img"
          />
            </div>
          </div>
          <div className="row justify-content-center text-center review-section">
            <div className="col-md-7 colum">
              <Swiper
                modules={[Pagination, Autoplay]} // Add Pagination and Autoplay modules
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }} // Set the delay to 3000ms (3 seconds)
                data-aos="fade-up"
                data-aos-delay={100}
                className="testimonials-slider"
              >
                <SwiperSlide>
                  <div className="review text-center">
                    <p className="stars text-center">
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill " />
                    </p>
                    <h3>Excellent App!</h3>
                    <blockquote>
                      <p>
                        Its robust feature set, excellent performance, and high
                        level of customization make it a standout choice. I
                        highly recommend it to anyone looking to streamline
                        their gym operations and enhance member engagement.
                      </p>
                    </blockquote>
                    <span className="text-black name">
                    shrutika wankhade , — App User
                    </span>
                    <p className="review-user text-center">
                      {/* <img
                        src="assets/img/person_1.jpg"
                        alt="Image"
                        className="img-fluid rounded-circle mb-3"
                      /> */}
                      {/* <span className="d-block">
                        <span className="text-black name">Jean Doe</span>, — App User
                      </span>  */}
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="review text-center">
                    <p className="stars text-center">
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill " />
                    </p>
                    <h3>This App is easy to use!</h3>
                    <blockquote>
                      <p>
                        It's a very nice application with beautiful, easy to use
                        UI and all essential features required to manage the gym
                        or any fitness center.
                      </p>
                    </blockquote>

                    <span className="text-black name">
                    Amol Hirkane , — App User
                    </span>

                    <p className="review-user text-center">
                      {/* <img
                        src="assets/img/person_2.jpg"
                        alt="Image"
                        className="img-fluid rounded-circle mb-3"
                      /> */}
                      {/* <span className="d-block">
                        <span className="text-black name">Johan Smith</span>, — App User
                      </span> */}
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="review text-center">
                    <p className="stars text-center">
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill " />
                    </p>
                    <h3>Awesome functionality!</h3>
                    <blockquote>
                      <p>
                      User frinedly. Easy to use and more helpfull apllication
                      </p>
                    </blockquote>
                    <span className="text-black name">
                    Naresh Nikam , — App User
                    </span>
                    <p className="review-user text-center">
                      {/* <img
                        src="assets/img/person_3.jpg"
                        alt="Image"
                        className="img-fluid rounded-circle mb-3"
                      /> */}
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* End Testimonials Section */}
    </>
  );
}

export default Review;
